body {
  font-family: Arial, sans-serif;
  background-color: #82b74b;
  color: #fff;
  padding: 30px;
}
.main-container {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unsubscribe-container {
  background-color: #40916c;
  padding: 50px 50px;
  border-radius: 8px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subscribe_icon {
  font-size: 7rem;
  color: white;
  height: 115px;
}
.unsubscribe-heading {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
}
.unsubscribe-description {
  font-size: 18px;
  max-width: 400px;
  text-align: center;
  padding: 10px 5px;
}
.input_box {
  width: 500px;

  padding-top: 10px;
}
.input-tag {
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.input_element {
  height: 95%;
  width: 90% !important;
  border: none;
  font-size: 18px;
  outline: none;
  padding: 8px;
  background-color: none;
}

.recaptcha_box {
  padding:0 8px;
}

.errorMessage {
  color: #ffe166;
  padding:10px;
}
.unsubscribe-button {
  height: 50px;
  margin-top: 10px;
  width: 400px;
  font-size: 20px;
  color: white;
  border-radius: 4px;
  border: 1px solid white;
  background-color: #40916c;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .unsubscribe-container {
    background-color: #40916c;
    padding: 20px 5px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input_box {
    width: 92.5%;
  }
  .unsubscribe-button {
    height: 50px;
    margin-top: 10px;
    width: 210px;
    font-size: 20px;
    color: white;
    border-radius: 4px;
    border: 1px solid white;
    background-color: #40916c;
    cursor: pointer;
  }
  .unsubscribe-heading {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
  }
  .input_wrapper {
    width: 94%;
    margin-top: 5px;
    height: 50px;
    background-color: white;
    border-radius: 4px;
    padding: 0px 10px;
  }
}
